import axios from 'axios';
import circle from '@turf/circle';


const turf_circle_radius = (coordinates, range) => {
    // const radius = 5; // radius in miles
    // const center = [lng, lat];
    const options = {
        steps: 50, 
        units: range.type 
    };
    const circle_range = circle(coordinates, range.distance, options);
    let geometry = circle_range['geometry']
    return geometry
}

export async function FindRange(markerlatlng, range={distance: null, type: null}, idvalFinal=null){
    var token = localStorage.getItem("auth-token");
    // console.log('idvalFinal: ', idvalFinal)
    const center = [markerlatlng[1], markerlatlng[0]]
    
    // RADIUS CIRCLE RANGE TYPE
    const circle_types = {'Circle Radius Miles': 'miles', 'Circle Radius Meters': 'meters'}
    if(circle_types[range.type]){
        var turf_circle_geo = {data: {}}
        range['type'] = circle_types[range.type]
        turf_circle_geo['data']['geometry'] = turf_circle_radius(markerlatlng, range);
        // console.log('turf_circle_geo: ', turf_circle_geo)
        // console.log('swapLatLngToLngLat: ', turf_circle_geo['data']['geometry']['coordinates'])
        return turf_circle_geo
    }

    try{
        let distance = await axios.post('/gettheurl/distance', { record_id: idvalFinal?._id, center,  bounds:[], range}, {headers:{ "auth-token": token}});
        let distance_data = await distance.data
        return distance_data

    }catch(e){
        console.log('e: ', e.response.data)
        alert(e.response.data)
        return null
    }
}